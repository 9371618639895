import {useParams} from "react-router-dom";
import Author from "components/Author/Author";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {translate} from "common/translator/translator";
import Page from "../Page";

const AuthorPage = () => {
    const {id} = useParams()
    const {requester} = useLoading()
    const [author, setAuthor] = useState(null)
    const apiUrl = process.env.REACT_APP_API_URL
    const name = author ? `${author.firstname} ${author.lastname}` : null

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/author/${id}`, (response) => {
            const author = response
            setAuthor(author)
        })
    }, [])

    return <Page title={translate('navigation.link.authors.label.page-title') + ` - ${name}`}>
        {author && <>
            <Author id={Number(id)}
                    name={name}
                    title={author.title}
                    text={author.text}
            />
        </>}
    </Page>
}
export default AuthorPage;