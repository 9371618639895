import "./Navigation.scss";
import {translate} from "common/translator/translator";
import {NavLink} from "react-router-dom";

const navigationLinks = [
    {
        target: "authors",
        label: translate('navigation.link.authors.label'),
        subLabel: translate('navigation.link.authors.sub-label')
    },
    {
        target: "articles",
        label: translate('navigation.link.articles.label'),
        subLabel: translate('navigation.link.articles.sub-label')
    },
    {
        target: "topics",
        label: translate('navigation.link.topics.label'),
        subLabel: translate('navigation.link.topics.sub-label')
    },
    {
        target: "about",
        label: translate('navigation.link.about.label'),
        subLabel: translate('navigation.link.about.sub-label')
    },
]

function Navigation() {
    const renderLinks = () => {
        return navigationLinks.map(link => {
            return <NavLink to={link.target} key={link.label}>
                <span className={"main"}>{link.label.toUpperCase()}</span>
                <span className={"sub"}>{link.subLabel}</span>
            </NavLink>
        })
    }
    return <div id={"navigation"}>
        {renderLinks()}
    </div>
}

export {Navigation, navigationLinks};