import WithSidebarContent from "components/Layout/Content/WithSidebar/WithSidebarContent";
import {translate} from "common/translator/translator";
import {useEffect, useState} from "react";
import React from "react";
import {useLoading} from "@kof/react-common";
import Article from "components/Article/Article";
import {Link, useParams} from "react-router-dom";
import "./ArticlePage.scss";
import Page from "../Page";

const ArticlePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const {requester} = useLoading()
    const {id} = useParams()
    const [article, setArticle] = useState(null)
    const title = article ? article.title : ''

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/article/${id}`, (response) => {
            const article = response
            setArticle(article)
        })
    }, [])

    return <Page title={title}>
        <div id={'article-page'}>
            {Boolean(article) &&
                <WithSidebarContent
                    main={<>
                        <Article title={article.title} authors={article.authors} date={article.date}
                                 html={article.text}/>
                    </>}
                    side={<>
                        <h1>{translate('page.article.authors')}</h1>
                        {article.authors.map(author => <React.Fragment key={author.id}>
                            <h2><Link to={`/authors/${author.id}`}>{author.firstname} {author.lastname}</Link></h2>
                            <Link to={`/authors/${author.id}`}><img src={`/authors/${author.id}.jpg`}/></Link>
                        </React.Fragment>)}
                    </>}
                />
            }
        </div>
    </Page>
}
export default ArticlePage;