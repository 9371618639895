import "./Footer.scss";
import {Link} from "react-router-dom";
import {translate} from "common/translator/translator";
import {navigationLinks} from "../Navigation/Navigation";
import React from "react";

function Footer() {
    const layoutLinks = [{target: '/', label: 'Home'}].concat(navigationLinks)
    const partnerLinks = [
        {target: 'http://www.voxeu.org', label: 'footer.links.vox'},
        {target: 'http://www.telos-eu.com', label: 'footer.links.telos'},
        {target: 'http://www.mejudice.nl', label: 'footer.links.me-judice'},
        {target: 'http://www.lavoce.info', label: 'footer.links.la-voce'},
        {target: 'http://www.fedeablogs.net/economia/', label: 'footer.links.nada'},
    ]
    const pages = [
        {target: '/imprint', label: 'footer.page.imprint'},
        {target: '/legal', label: 'footer.page.legal'},
    ]
    const renderLinkList = (links, internal) => {
        return <p>
            {links.map((link, index) => {
                const isLast = links.length - 1 === index
                const renderedLink = internal ?
                    <Link to={link.target}>{translate(link.label)}</Link> :
                    <a href={link.target} target={'_blank'}>{translate(link.label)}</a>

                const element = !isLast ? <>{renderedLink} | </> : renderedLink
                return <React.Fragment key={link.label}>
                    {element}
                </React.Fragment>
            })}
        </p>
    }
    return <div id={"footer"}>
        <div className={"links"}>
            {renderLinkList(layoutLinks, true)}
            {renderLinkList(partnerLinks, false)}
            {renderLinkList(pages, true)}
        </div>
        <div id={'explanation'}>
            <img src={"/kof-logo.png"}/>
            <p>
                {translate('footer.explanation.service-of')}<br/>
                {translate('footer.explanation.kof-eth')}<br/>
            </p>
        </div>
    </div>
}

export default Footer;