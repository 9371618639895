import PropTypes from 'prop-types';
import "./Author.scss";
import {Link} from "react-router-dom";
import {translate} from "common/translator/translator";

const Author = (props) => {
    return <div id={"author"}>
        <div id={"image-column"}>
            <img src={`/authors/${props.id}.jpg`}/>
        </div>
        <div>
            <h1>{props.name}</h1>
            <h2>{props.title}</h2>
            <div dangerouslySetInnerHTML={{__html: props.text}}></div>
            <p><Link to={"/authors"}>{translate('page.authors.back-to-overview')}</Link></p>
        </div>
    </div>
}

Author.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default Author;