import "./Header.scss";
import {translate} from "common/translator/translator";
import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";

function Header() {
    const [text, setText] = useState('')
    const navigate = useNavigate();

    const search = () => {
        setText('')
        navigate(`/search/${text}`)
    }

    return <div id={"header"}>
        <Link to={"/"} id={"logo"}>
            <img src={'/logo.jpg'}/>
        </Link>
        <div id={"search"}>
            <input type={"text"} value={text}
                   onChange={event => setText(event.target.value)}
                   onKeyDown={(event) => {
                       if (event.key === 'Enter') {
                           search()
                       }
                   }}
            />
            <a href={"#"} onClick={event => {
                event.preventDefault()
                search()
            }}>
                {translate('header.search.label')}
            </a>
        </div>
    </div>
}

export default Header;