import ArticlePreviews from "components/Articles/ArticlePreviews";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {useLocation, useParams} from "react-router-dom";
import Page from "../../Page";
import {translate} from "common/translator/translator";

const TopicsArticlesPage = () => {
    const location = useLocation()
    const apiUrl = process.env.REACT_APP_API_URL
    const {requester} = useLoading()
    const {topicId, subtopicId} = useParams()
    const [articles, setArticles] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const getEndpointUrl = () => {
        if (topicId) {
            return `${apiUrl}/api/rest/article?topic=${topicId}`
        }
        if (subtopicId) {
            return `${apiUrl}/api/rest/article?subtopic=${subtopicId}`
        }
    }

    useEffect(() => {
        requester.get(getEndpointUrl(), (response) => {
            const articles = response
            setArticles(articles)
        })
    }, [])

    return <Page title={translate('navigation.link.articles.label') + ` - ${location.state.title}`}>
        {Boolean(articles.length) && <>
            <h1>{location.state.title}</h1>
            <ArticlePreviews items={articles}
                             currentPage={currentPage}
                             setCurrentPage={newCurrentPage => setCurrentPage(newCurrentPage)}
            />
        </>}
    </Page>
}
export default TopicsArticlesPage;