import {translate} from "common/translator/translator";
import "./Imprint.scss"
import Page from "../Page";

const ImprintPage = () => {

    return <Page title={translate('footer.page.imprint')}>
        <div id={"imprint"}>
            <h1>{translate('page.imprint.publisher')}</h1>
            <p dangerouslySetInnerHTML={{__html: translate('page.imprint.publisher.address')}}></p>
            <h1>{translate('page.imprint.responsibility')}</h1>
            <p dangerouslySetInnerHTML={{__html: translate('page.imprint.responsibility.responsible')}}></p>
            <h1>{translate('page.imprint.responsibility.team')}</h1>
            <p dangerouslySetInnerHTML={{__html: translate('page.imprint.responsibility.team.people')}}></p>
        </div>
    </Page>
}
export default ImprintPage;