import {translate} from "common/translator/translator";
import AuthorPreviews from "components/AuthorPreviews/AuthorPreviews";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import Page from "../Page";

const AuthorsPage = () => {
    const {requester} = useLoading()
    const [authors, setAuthors] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL
    const founderAuthors = authors.filter(item => item.isFounder)
    const normalAuthors = authors.filter(item => !item.isFounder)

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/author`, (response) => {
            const authors = response
            setAuthors(authors)
        })
    }, [])

    return <Page title={translate('navigation.link.authors.label.page-title')}>
        {Boolean(authors.length) && <>
            <h1>{translate('page.authors.title.founders')}</h1>
            <AuthorPreviews items={founderAuthors}/>
            <h1>{translate('page.authors.title')}</h1>
            <AuthorPreviews items={normalAuthors}/>
        </>}
    </Page>
}
export default AuthorsPage;