import PropTypes from 'prop-types';
import "./AuthorPreviews.scss";
import {Link} from "react-router-dom";
import {translate} from "common/translator/translator";

const AuthorPreviews = (props) => {
    return <div className={"author-previews"}>
        {props.items.map(item => <div className={"item"} key={item.id}>
            <Link to={`/authors/${item.id}`}>
                <img src={`/authors/${item.id}.jpg`}/>
                <p>
                    {item.name}
                </p>
            </Link>
        </div>)}
    </div>
}

AuthorPreviews.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        articlesCount: PropTypes.number.isRequired,
    })).isRequired,
};

export default AuthorPreviews;