import {translate} from "common/translator/translator";
import "./LegalPage.scss";
import Page from "../Page";

const LegalPage = () => {
    return <Page title={translate('footer.page.legal')}>
        <div id={'legal'}>
            <h1>{translate('page.legal.title')}</h1>
            <div dangerouslySetInnerHTML={{__html: translate('page.legal.text')}}></div>
        </div>
    </Page>
}
export default LegalPage;