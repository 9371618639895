import {translate} from "common/translator/translator";
import ArticlePreviews from "components/Articles/ArticlePreviews";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {useLocation, useParams} from "react-router-dom";
import Page from "./Page";

const SearchPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const {requester} = useLoading()
    const location = useLocation()
    const {text} = useParams()
    const [articles, setArticles] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    const getEndpoint = () => {
        if (text) {
            return `${apiUrl}/api/rest/article?search=${text}`
        }
        return `${apiUrl}/api/rest/article`
    }

    useEffect(() => {
        setArticles([])
        requester.get(getEndpoint(), (response) => {
            const articles = response
            setArticles(articles)
        })
    }, [location.key])

    return <Page title={translate('page.search.page-title')}>
        {Boolean(articles.length) && <>
            <h1>{translate('page.search.title')}</h1>
            <p>"{text}"</p>
            <ArticlePreviews items={articles}
                             currentPage={currentPage}
                             setCurrentPage={newCurrentPage => setCurrentPage(newCurrentPage)}
            />
        </>}
    </Page>
}
export default SearchPage;