import "./Layout.scss";
import {Outlet} from "react-router-dom";
import {Navigation} from "./Navigation/Navigation";
import Header from "./Header/Header";
import Content from "./Content/Content";
import Footer from "./Footer/Footer";
import {useLoading, LoadingSpinner} from "@kof/react-common";
import {translate} from "common/translator/translator";
import {useLocation} from 'react-router-dom'
import {useEffect} from "react";
import ArchiveHint from "./ArchiveHint/ArchiveHint";

export default function Layout() {
    const {loading, setRequestError, requestError} = useLoading()
    const location = useLocation()

    useEffect(() => {
        setRequestError(null)
    }, [location])

    return <div id={"wrapper"}>
        <Header/>
        <Navigation/>
        <ArchiveHint/>
        <Content>
            {loading && <LoadingSpinner/>}
            {requestError && <p>{translate('request.error')}</p>}
            <Outlet/>
        </Content>
        <Footer/>
    </div>
}