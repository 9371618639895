import PropTypes from 'prop-types';
import "./Article.scss";
import {translate} from "common/translator/translator";
import {Link} from "react-router-dom";
import moment from "moment";
import 'moment/locale/de';
import {apiMomentFormat} from "common/date";

const Article = (props) => {

    const concatenateAuthors = (authors) => {
        const elements = []
        const lastIndex = authors.length - 1
        const penultimateIndex = authors.length - 2
        authors.forEach((author, index) => {
            elements.push(<Link to={`/authors/${author.id}`} key={author.id}>
                {author.firstname} {author.lastname}
            </Link>)
            if (index === lastIndex) {
                return;
            }
            if (index === penultimateIndex) {
                elements.push(` ${translate('page.articles.authors.and')} `)
                return
            }
            elements.push(', ')
        })
        return elements
    }

    const renderMoreLink = (articleId) => {
        return <span className={'more'}>
            [ <Link to={`/articles/${articleId}`}>{translate('page.articles.article.more')}</Link> ]
        </span>
    }

    return <div className={"article"}>
        <h1>{props.title}</h1>
        <div className={"authors"}>
            {concatenateAuthors(props.authors)}, {moment(props.date, apiMomentFormat).format('Do MMM YYYY')}
        </div>
        {Boolean(props.teaser) && <p>{props.teaser} {renderMoreLink(props.id)}</p>}
        {Boolean(props.html) && <div className={'text'} dangerouslySetInnerHTML={{__html: props.html}}></div>}
    </div>
}

Article.propTypes = {
    id: PropTypes.number,
    title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    authors: PropTypes.arrayOf(PropTypes.shape({
        firstname: PropTypes.string.isRequired,
        lastname: PropTypes.string.isRequired,
    })).isRequired,
    date: PropTypes.string.isRequired,
    teaser: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    html: PropTypes.string,
};

export default Article;