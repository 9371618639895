import {translate} from "common/translator/translator";
import ArticlePreviews from "components/Articles/ArticlePreviews";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {Link, useParams} from "react-router-dom";
import ArchiveList from "components/Articles/ArchiveList/ArchiveList";
import WithSidebarContent from "components/Layout/Content/WithSidebar/WithSidebarContent";
import moment from "moment";
import 'moment/locale/de';
import {apiMomentFormat} from "common/date";
import Page from "../Page";

const ArticlesPage = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const {requester} = useLoading()
    const {year, month} = useParams()
    const [articles, setArticles] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const filterArticles = (articles) => {
        if (year && month) {
            return articles.filter(article => {
                const articleDate = moment(article.date, apiMomentFormat)
                return Number(year) === articleDate.year() &&
                    Number(month) === (articleDate.month() + 1)
            })
        }
        if (year) {
            return articles.filter(article => Number(year) === moment(article.date, apiMomentFormat).year())
        }
        return articles
    }
    const filteredArticles = filterArticles(articles)

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/article`, (response) => {
            const articles = response
            setArticles(articles)
        })
    }, [])

    return <Page title={translate('navigation.link.articles.label')}>
        <div id={"articles-page"}>
            {Boolean(articles.length) &&
                <WithSidebarContent
                    main={<>
                        <h1>
                            {translate('page.articles.title')}
                        </h1>
                        <ArticlePreviews items={filteredArticles}
                                         currentPage={currentPage}
                                         setCurrentPage={newCurrentPage => setCurrentPage(newCurrentPage)}
                        />
                    </>}
                    side={<>
                        <h1><Link to={'/articles'}>{translate('page.articles.archive.title')}</Link></h1>
                        <ArchiveList items={articles} restartPagination={() => setCurrentPage(1)}/>
                    </>}
                />
            }
        </div>
    </Page>
}
export default ArticlesPage;