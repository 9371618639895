import ArticlePreviews from "components/Articles/ArticlePreviews";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {Link} from "react-router-dom";
import {translate} from "common/translator/translator";
import "./HomePage.scss";
import Page from "../Page";

const HomePage = () => {
    const apiUrl = process.env.REACT_APP_API_URL
    const [articles, setArticles] = useState([])
    const {requester} = useLoading()

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/article?limit=10`, (response) => {
            const articles = response
            setArticles(articles)
        })
    }, [])

    return <Page title={translate('page.home.title')}>
        <div id={'home-page'}>
            {Boolean(articles.length) && <>
                <ArticlePreviews items={articles} pagination={false}/>
                <p>
                    <Link to={'articles'} className={'show-older'}>
                        {translate('page.home.articles.show-older')}
                    </Link>
                </p>
            </>}
        </div>
    </Page>
}
export default HomePage;