import React from 'react';
import PropTypes from 'prop-types';
import "./ArchiveList.scss";
import {Link} from "react-router-dom";
import {translate} from "common/translator/translator";
import moment from "moment";
import 'moment/locale/de';

const ArchiveList = (props) => {

    const groupArticlesByYear = () => {
        const articleGroupedYears = []
        const getYearIndex = (year) => articleGroupedYears.findIndex(groupedYear => groupedYear.year === year)
        const getMonthIndex = (year, monthNumber) => {
            const yearIndex = getYearIndex(year)
            return articleGroupedYears[yearIndex].months.findIndex(month => month.number === monthNumber)
        }

        props.items.forEach(article => {
            const dateSplit = article.date.split('.')
            const year = Number(dateSplit[2])
            const monthNumber = Number(dateSplit[1])

            if (getYearIndex(year) < 0) {
                articleGroupedYears.push({year: year, months: []})
            }
            const yearIndex = getYearIndex(year)
            if (getMonthIndex(year, monthNumber) < 0) {
                articleGroupedYears[yearIndex].months.push({
                    number: monthNumber,
                    label: moment().month(monthNumber - 1).format('MMMM'),
                    count: 0,
                })
            }

            const monthIndex = getMonthIndex(year, monthNumber)
            articleGroupedYears[yearIndex].months[monthIndex].count++
        })
        return articleGroupedYears
    }
    const reorderMonths = (articleGroupedYears) => {
        return articleGroupedYears.map(year => {
            year.months = year.months.reverse()
            return year
        })
    }
    const articleGroupedYears = reorderMonths(groupArticlesByYear())

    return <div id={"articles-archive-list"}>
        {articleGroupedYears.map(year => <React.Fragment key={year.year}>
            <p className={"year"}>
                <Link to={`/articles/range/${year.year}`} onClick={props.restartPagination}>
                    {year.year}
                </Link>
            </p>
            <ul>
                {year.months.map(month => {
                    return <li key={`${year.year}-${month.number}`}>
                        <Link to={`/articles/range/${year.year}/${month.number}`} onClick={props.restartPagination}>
                            {month.label} ({month.count} {translate('page.articles.title')})
                        </Link>
                    </li>
                })}
            </ul>
        </React.Fragment>)}
    </div>
}

ArchiveList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.string.isRequired,
    })),
    restartPagination: PropTypes.func.isRequired,
};

export default ArchiveList;