import PropTypes from 'prop-types';
import {useEffect} from "react";
import {translate} from "common/translator/translator";

const Page = (props) => {
    const siteName = translate('site')

    useEffect(() => {
        document.title = `${siteName}: ${props.title}`;
    });

    return props.children;
}
Page.propTypes = {
    title: PropTypes.string.isRequired,
};
export default Page;