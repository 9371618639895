import PropTypes from 'prop-types';
import "./ArticlePreviews.scss";
import {translate} from "common/translator/translator";
import {Link, useNavigate} from "react-router-dom";
import Article from "../Article/Article";

const ArticlePreviews = (props) => {
    const navigate = useNavigate()
    const perPage = 10
    const currentPage = (props.pagination === false) ? 1 : props.currentPage
    const limitItems = (items) => {
        const limitingOffset = perPage * currentPage - perPage
        return items.slice(limitingOffset, limitingOffset + perPage)
    }
    const limitedItems = limitItems(props.items)

    const renderPagination = () => {
        const pages = Math.ceil(props.items.length / perPage);

        const changePage = (event, newPage) => {
            event.preventDefault()
            props.setCurrentPage(newPage)
        }
        const previous = <a href={"#"} onClick={(event) => changePage(event, currentPage - 1)} key={'previous'}>
            {translate('pagination.previous')}
        </a>
        const pageText = <span
            key={'page'}>{translate('pagination.page')} {currentPage}/{pages}</span>
        const next = <a href={"#"} onClick={(event) => changePage(event, currentPage + 1)} key={'next'}>
            {translate('pagination.next')}
        </a>
        const isLastPage = currentPage === pages

        const showingElements = []
        if (currentPage > 1) {
            showingElements.push(previous)
            showingElements.push(' | ')
        }
        showingElements.push(pageText)
        if (!isLastPage) {
            showingElements.push(' | ')
            showingElements.push(next)
        }
        return <div id={'pagination'}>
            {showingElements}
        </div>
    }

    return <div id={"article-previews"}>
        {props.pagination !== false && renderPagination()}
        {limitedItems.map(article => <div className={'article-preview'} key={article.id}>
            <Article id={article.id}
                     title={<Link to={`/articles/${article.id}`}>{article.title}</Link>}
                     authors={article.authors} date={article.date}
                     teaser={<Link to={`/articles/${article.id}`}>{article.teaser}</Link>}
            />
        </div>)}
    </div>
}

ArticlePreviews.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        authors: PropTypes.array.isRequired,
        date: PropTypes.string.isRequired,
        teaser: PropTypes.string.isRequired,
    })),
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
    pagination: PropTypes.bool,
};

export default ArticlePreviews;