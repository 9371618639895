import {translate} from "common/translator/translator";
import {useEffect, useState} from "react";
import {useLoading} from "@kof/react-common";
import {Link} from "react-router-dom";
import "./TopicsPage.scss";
import Page from "../Page";

const TopicsPage = () => {
    const {requester} = useLoading()
    const [topics, setTopics] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL
    const formatLabel = (label) => label.split('|').join(': ')

    useEffect(() => {
        requester.get(`${apiUrl}/api/rest/topic`, (response) => {
            const topics = response
            setTopics(topics)
        })
    }, [])

    return <Page title={translate('navigation.link.topics.label')}>
        <div id={'topics-page'}>
            {Boolean(topics.length) && <>
                <h1>{translate('page.topics.title')}</h1>
                {topics.map(topic => {
                    return <div key={topic.id}>
                        <p>
                            <Link to={`/topics/topic/${topic.id}`} state={{title: formatLabel(topic.label)}}>
                                <strong>{formatLabel(topic.label)}</strong>
                            </Link>
                        </p>
                        <ul>
                            {topic.children.map(child => {
                                if (child.articlesCount === 0) {
                                    return
                                }
                                return <li key={child.id}>
                                    <Link to={`/topics/subtopic/${child.id}`} state={{title: formatLabel(child.label)}}>
                                        {formatLabel(child.label)}
                                    </Link> ({child.articlesCount} {translate('page.topics.articles')})
                                </li>
                            })}
                        </ul>
                    </div>
                })}
            </>}
        </div>
    </Page>
}
export default TopicsPage;