import PropTypes from 'prop-types';
import "./WithSidebarContent.scss";

function WithSidebarContent(props) {
    return <div id={"with-sidebar-content"}>
        <div id={"main-column"}>{props.main}</div>
        <div id={"side-column"}>{props.side}</div>
    </div>
}

WithSidebarContent.propTypes = {
    main: PropTypes.object.isRequired,
    side: PropTypes.object.isRequired,
};

export default WithSidebarContent;