import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {LoadingProvider} from "@kof/react-common";
import Layout from "./components/Layout/Layout";
import HomePage from "./pages/Home/HomePage";
import AuthorsPage from "./pages/Authors/AuthorsPage";
import AuthorPage from "./pages/Authors/AuthorPage";
import ArticlesPage from "./pages/Articles/ArticlesPage";
import ArticlePage from "./pages/Articles/ArticlePage";
import TopicsPage from "./pages/Topics/TopicsPage";
import TopicsArticlesPage from "./pages/Topics/Articles/TopicsArticlesPage";
import AboutPage from "./pages/About/AboutPage";
import SearchPage from "./pages/SearchPage";
import ImprintPage from "./pages/Imprint/ImprintPage";
import LegalPage from "./pages/Legal/LegalPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {index: true, element: <HomePage/>},
            {path: "authors", element: <AuthorsPage/>},
            {path: "authors/:id", element: <AuthorPage/>},
            {path: "articles", element: <ArticlesPage/>},
            {path: "articles/range/:year", element: <ArticlesPage/>},
            {path: "articles/range/:year/:month", element: <ArticlesPage/>},
            {path: "articles/:id", element: <ArticlePage/>},
            {path: "topics", element: <TopicsPage/>},
            {path: "topics/topic/:topicId", element: <TopicsArticlesPage/>},
            {path: "topics/subtopic/:subtopicId", element: <TopicsArticlesPage/>},
            {path: "about", element: <AboutPage/>},
            {path: "search", element: <SearchPage/>},
            {path: "search/:text", element: <SearchPage/>},
            {path: "imprint", element: <ImprintPage/>},
            {path: "legal", element: <LegalPage/>},
            {path: "*", element: <HomePage/>},
        ],
    },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <LoadingProvider>
            <RouterProvider router={router}/>
        </LoadingProvider>
    </React.StrictMode>
);