import {translate} from "common/translator/translator";
import './AboutPage.scss';
import Page from "../Page";

const AboutPage = () => {
    return <Page title={translate('navigation.link.about.label.page-title')}>
        <div id={'about-page'}>
            <h1>{translate('page.about.title')}</h1>
            <div dangerouslySetInnerHTML={{__html: translate('page.about.text')}}></div>
        </div>
    </Page>
}
export default AboutPage;